import * as React from 'react';
import {graphql} from 'gatsby';

import {Counter} from '../components/counter';
import Layout from '../components/layout';
import GallerySection from '../components/sections/gallery-section';
import HeroSection from '../components/sections/hero-section';
import SponsorsSectionNew from '../components/sections/sponsors-section-new';
import SponsorsSectionOld from '../components/sections/sponsors-section-old';
import VenueSection from '../components/sections/venue-section';
import SpeakersPreviewSection from '../components/sections/speakers-preview-section';
import CONTENT from '../components/content';
import {InternalLinkButton} from '../components/components';
import WorkshopTopicSection from '../components/sections/workshop-topic-section';

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    workshop: allMdx(
      sort: {fields: frontmatter___order, order: ASC}
      filter: {fileAbsolutePath: {regex: "/speakers/workshop/"}}
    ) {
      edges {
        node {
          id
          body
          slug
          frontmatter {
            order
            fullName
            title
            company
            talkTitle
            website
            github
            linkedin
            twitter
            imageSrc {
              childImageSharp {
                large: gatsbyImageData(
                  height: 180
                  placeholder: BLURRED
                  formats: [AUTO, WEBP, AVIF]
                )
                small: gatsbyImageData(
                  height: 120
                  placeholder: BLURRED
                  formats: [AUTO, WEBP, AVIF]
                )
              }
            }
            previewSrc {
              childImageSharp {
                gatsbyImageData(height: 340, placeholder: BLURRED, formats: [AUTO, WEBP, AVIF])
              }
            }
          }
        }
      }
    }
    speakers: allMdx(
      sort: {fields: frontmatter___order, order: ASC}
      filter: {fileAbsolutePath: {regex: "/speakers/2024/"}}
    ) {
      edges {
        node {
          id
          body
          slug
          frontmatter {
            order
            fullName
            title
            company
            talkTitle
            website
            github
            linkedin
            twitter
            countryCode
            published
            imageSrc {
              childImageSharp {
                gatsbyImageData(height: 340, placeholder: BLURRED, formats: [AUTO, WEBP, AVIF])
              }
            }
          }
        }
      }
    }
    mcs: allMdx(
      sort: {fields: frontmatter___order, order: ASC}
      filter: {fileAbsolutePath: {regex: "/speakers/mcs/"}}
    ) {
      edges {
        node {
          id
          body
          slug
          frontmatter {
            order
            fullName
            title
            company
            talkTitle
            website
            github
            linkedin
            twitter
            countryCode
            published
            imageSrc {
              childImageSharp {
                gatsbyImageData(height: 340, placeholder: BLURRED, formats: [AUTO, WEBP, AVIF])
              }
            }
          }
        }
      }
    }
  }
`;

const IndexPage = ({data}: {data: any}) => {
  const speakers = data.speakers.edges;
  const workshop = data.workshop.edges;
  const mcs = data.mcs.edges;

  return (
    <Layout>
      <HeroSection />
      <SpeakersPreviewSection title="SPEAKERS" subtitle="" speakers={speakers} />
      <WorkshopTopicSection speakers={workshop} />
      {/* <div className="section-description" style={{textAlign: 'center'}}>
        <h2>WANT TO JOIN AS A SPEAKER?</h2>
        <InternalLinkButton label="CALL FOR PAPERS" url="/call-for-papers"></InternalLinkButton>
      </div> */}
      {/* <SpeakersPreviewSection
        title="MCs"
        subtitle="Meet our wonderful masters of ceremonies"
        speakers={mcs}
      /> */}
      <Counter
        dateAndTime={{year: 2024, month: 12, day: 5, hour: 8, minute: 0}}
        label="We're ready to launch in:"
      />

      {/* <SponsorsSectionOld sponsors={CONTENT.SPONSORS_OLD} /> */}
      <SponsorsSectionNew />
      <VenueSection />
      <GallerySection />
    </Layout>
  );
};
export default IndexPage;
