import React, {useEffect, useState} from 'react';
import styled from 'styled-components';
import {breakpointsMediaQueries, COLORS} from '../style/my-theme';
import {ExternalLinkButton} from './components';

const StyledCounter = styled.div.attrs({
  className: 'counter'
})`
  display: flex;
  flex-direction: column;
  text-align: center;
  padding: 2rem 0;
  margin: ${({isTicketsPage}: {isTicketsPage?: boolean}) =>
    isTicketsPage ? '0 0 2rem 0' : '4rem 0'};
  background-image: url('/images/footer-stickers.jpeg');

  h2 {
    flex-direction: column;
    text-align: center;
    color: ${COLORS.blueLight};

    ${breakpointsMediaQueries.md} {
      font-size: 1.2rem;
    }
  }
  .counter-wrapper {
    display: flex;
    justify-content: center;
    gap: 4rem;
    ${breakpointsMediaQueries.md} {
      gap: initial;
    }
  }

  .counter-item {
    width: 100px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 0.25rem;

    span.label {
      font-size: 0.8rem;
      color: ${COLORS.blueLight};
      width: 100%;
      text-transform: uppercase;
      font-weight: 700;
      text-align: center;
      margin-top: 0.1rem;
    }

    span.value {
      padding: 0 0.5rem;
      font-size: 4rem;
      color: ${COLORS.white};
      text-align: center;
    }

    @media (max-width: 500px) {
      span.value {
        font-size: 2.5rem;
      }
    }
  }
`;

interface CounterProps {
  dateAndTime: {
    year: number;
    month: number;
    day: number;
    hour: number;
    minute: number;
  };
  label: string;
  isTicketsPage?: boolean;
}

export const Counter = ({dateAndTime, label, isTicketsPage}: CounterProps) => {
  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft(0, 0, 0, 0, 0));

  useEffect(() => {
    const timer = setTimeout(
      () =>
        setTimeLeft(
          calculateTimeLeft(
            dateAndTime.year,
            dateAndTime.month,
            dateAndTime.day,
            dateAndTime.hour,
            dateAndTime.minute
          )
        ),
      1000
    );
    return () => clearTimeout(timer);
  }, [timeLeft]);

  return (
    <StyledCounter suppressHydrationWarning isTicketsPage={isTicketsPage}>
      <h2>{label}</h2>
      <div className="counter-wrapper">
        <div className="counter-item">
          <span className="value">{String(timeLeft.days).padStart(2, '0')}</span>
          <span className="label">Days</span>
        </div>

        <div className="counter-item">
          <span className="value">{String(timeLeft.hours).padStart(2, '0')}</span>
          <span className="label">Hours</span>
        </div>

        <div className="counter-item">
          <span className="value">{String(timeLeft.minutes).padStart(2, '0')}</span>
          <span className="label">Min</span>
        </div>

        <div className="counter-item">
          <span className="value">{String(timeLeft.seconds).padStart(2, '0')}</span>
          <span className="label">Sec</span>
        </div>
      </div>
      {/* <ExternalLinkButton label="SCHEDULE" url="/armada-schedule-2023-v2.pdf"></ExternalLinkButton> */}
    </StyledCounter>
  );
};

type TimeLeft = {
  days: number;
  hours: number;
  minutes: number;
  seconds: number;
};

const calculateTimeLeft = (
  year: number,
  month: number,
  day: number,
  hour: number,
  minute: number
): TimeLeft => {
  // Create a Date object for the target time in CEST
  const targetDate = new Date(Date.UTC(year, month - 1, day, hour - 2, minute));

  const now = new Date();
  const difference = +targetDate - +now;

  let timeLeft: TimeLeft = {
    days: 0,
    hours: 0,
    minutes: 0,
    seconds: 0
  };

  if (difference > 0) {
    timeLeft = {
      days: Math.floor(difference / (1000 * 60 * 60 * 24)),
      hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
      minutes: Math.floor((difference / 1000 / 60) % 60),
      seconds: Math.floor((difference / 1000) % 60)
    };
  }

  return timeLeft;
};
