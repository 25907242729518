import React from 'react';
import styled from 'styled-components';
import {GatsbyImage, getImage} from 'gatsby-plugin-image';
import {Link} from 'gatsby';

import {ContainerLg} from '../components';
import {COLORS} from '../../style/my-theme';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faGithub, faTwitter, faLinkedin} from '@fortawesome/free-brands-svg-icons';

const StyledSpeakersPreviewSection = styled.section.attrs({
  className: 'speakers-overview-section'
})`
  margin: 4rem 0;

  .container-lg {
    .section-title {
      text-align: center;
      h1 {
        color: ${COLORS.blueLight};
        font-size: 2rem;
        margin: 0;
      }
      h2 {
        color: ${COLORS.primary};
        margin-top: 0;
      }
    }

    .speakers-wrapper {
      display: flex;
      flex-wrap: wrap;
      column-gap: 2rem;
      row-gap: 2rem;
      justify-content: center;

      .speaker-info {
        align-items: center;
        align-content: center;
        justify-content: center;
        display: flex;
        flex-direction: column;
        .gatsby-image-wrapper {
          max-width: 300px;
          max-height: 300px;

          img {
            border-radius: 50%;
            -o-object-fit: cover;
            object-fit: cover;
            -o-object-position: top;
            object-position: top;
            border-radius: 50%;
            border: 5px solid ${COLORS.yellow};
          }
        }
        .speaker-title {
          display: grid;
          padding: 1rem;
          color: ${COLORS.primary};
          text-align: center;

          .name {
            font-size: 1.5rem;
          }
          .company {
            font-weight: 600;
          }
        }
      }
    }
  }
`;

const SpeakerTile = ({speaker}: {speaker: any}) => {
  const image = getImage(speaker.node.frontmatter.imageSrc);
  return (
    <div className="speaker-info">
      {image && <GatsbyImage image={image} alt={speaker.node.frontmatter.fullName} />}
      <div className="speaker-title">
        <span className="name">{speaker.node.frontmatter.fullName}</span>
        <span className="company">{speaker.node.frontmatter.company}</span>
        <span className="title">{speaker.node.frontmatter.title}</span>
      </div>
    </div>
  );
};

// linear-gradient(126deg,rgba(27,171,200,1) 0%,rgba(246,223,25,1) 100%);
const StyledSpeakerTile = styled.div.attrs({
  className: 'speaker-card'
})`
  position: relative;
  max-width: 300px;
  min-width: 300px;
  height: 100%;
  padding: 1rem;
  border-radius: 0.5rem;
  background: linear-gradient(rgba(27, 171, 200, 1), rgba(246, 223, 25, 1));

  backdrop-filter: blur(200px);
  animation: 2s infinite linear alternate-reverse, 7s infinite linear;
  &:hover {
    animation-name: card3d, reflection3d;
    cursor: pointer;

    .bg,
    .character {
      animation: items3d 2s infinite linear alternate-reverse;
    }
  }

  .graphic-container {
    overflow: hidden;
    position: relative;
    width: 100%;
    aspect-ratio: 1/1;
    border-radius: 0.25rem;

    .speaker-links {
      display: flex;
      gap: 1rem;
      position: absolute;
      bottom: 0.5rem;
      right: 1rem;
      .link-box {
        background: #f6df19;
        color: #212121;
        width: 2rem;
        height: 2rem;
        text-align: center;
        line-height: 2rem;
        border-radius: 100%;
        display: block;
      }
    }

    .bg,
    .character {
      position: absolute;
      width: 100%;
    }
    .bg {
      scale: 1.3;
      height: 100%;
      object-fit: cover;
    }

    .character {
      object-fit: contain;
      height: 110%;
      top: 50%;
      translate: 0 -50%;
    }
  }
  .about {
    padding-top: 1rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    font-size: 1rem;
    color: black;

    .title {
      font-size: 1rem;
      font-weight: 600;
      margin-bottom: 0.3rem;
    }
    .company {
      font-size: 0.9rem;
    }
    .talk-title {
      margin-top: 4px;
      color: ${COLORS.azure200};
      word-wrap: break-word;
    }
  }

  @keyframes reflection3d {
    from {
      background-position: 0 0;
    }
    to {
      background-position: 300px 0;
    }
  }
  @keyframes card3d {
    from {
      transform: rotateY(20deg);
    }
    to {
      transform: rotateY(-20deg);
    }
  }

  @keyframes items3d {
    from {
      transform: translateX(-10%);
    }
    to {
      transform: translateX(10%);
    }
  }
`;

const SpeakerTileNew = ({speaker}: {speaker: any}) => {
  const image = getImage(speaker.node.frontmatter.imageSrc);
  return (
    <StyledSpeakerTile>
      <div className="graphic-container">
        <img
          className="bg"
          src="https://i.pinimg.com/originals/9b/56/47/9b56474c8e721792169cb5698e8ba4ab.png"
        />
        {image && (
          <GatsbyImage
            className="character"
            image={image}
            alt={speaker.node.frontmatter.fullName}
          />
        )}
        <div className="speaker-links">
          {speaker.node.frontmatter.github && (
            <a href={speaker.node.frontmatter.github} className="link-box" target="_blank">
              <FontAwesomeIcon icon={faGithub} />
            </a>
          )}
          {speaker.node.frontmatter.twitter && (
            <a href={speaker.node.frontmatter.twitter} className="link-box" target="_blank">
              <FontAwesomeIcon icon={faTwitter} />
            </a>
          )}
          {speaker.node.frontmatter.linkedin && (
            <a href={speaker.node.frontmatter.linkedin} className="link-box" target="_blank">
              <FontAwesomeIcon icon={faLinkedin} />
            </a>
          )}
        </div>
      </div>
      <div className="about">
        <label className="title">
          {speaker.node.frontmatter.fullName}{' '}
          <span className={`fi fi-${speaker.node.frontmatter.countryCode}`}></span>
        </label>

        <label className="company">
          {speaker.node.frontmatter.title}, <strong>{speaker.node.frontmatter.company}</strong>
        </label>
        <div className="talk-title">{speaker.node.frontmatter.talkTitle}</div>
      </div>
    </StyledSpeakerTile>
  );
};

const SpeakersPreviewSection = ({
  title,
  subtitle,
  speakers
}: {
  title: string;
  subtitle: string;
  speakers: any[];
}) => (
  <StyledSpeakersPreviewSection>
    <ContainerLg>
      <div className="section-title">
        <h1 id="speakers">{title}</h1>
        <h2>{subtitle}</h2>
      </div>
      <div className="speakers-wrapper">
        {speakers
          .filter((s: any) => s.node.frontmatter.published)
          .map((speaker: any) => {
            return (
              <Link key={speaker.node.id} to={`/speakers/${speaker.node.slug}`}>
                <SpeakerTileNew speaker={speaker} />
              </Link>
            );
          })}
      </div>
    </ContainerLg>
  </StyledSpeakersPreviewSection>
);

export default SpeakersPreviewSection;
