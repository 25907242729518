import {Link} from 'gatsby';
import {GatsbyImage, getImage} from 'gatsby-plugin-image';
import * as React from 'react';
import {useBreakpoint} from 'gatsby-plugin-breakpoints';
import styled from 'styled-components';
import {breakpointsMediaQueries, COLORS} from '../../style/my-theme';
import {ContainerLg} from '../components';

const StyledButton = styled.button`
  background: ${COLORS.blueLight};
  color: ${COLORS.primary};
  border: none;
  box-shadow: 0px 5px 0px ${COLORS.primary};
  border-radius: 0.5rem;
  padding: 0 1rem;
  height: 2rem;
  font-size: 1rem;
  font-weight: 400;
  outline: none;

  &:hover {
    background: ${COLORS.yellowLight};
    cursor: pointer;
  }
  ${breakpointsMediaQueries.md} {
    font-size: 0.75rem;
    margin: 2rem 0;
    width: auto;
    height: 2rem;
  }
`;

const StyledAgenda = styled.div.attrs({
  className: 'speakers-overview-section'
})`
  margin-top: 1rem;
  padding: 1rem;
  align-self: center;
  align-items: center;
  max-width: 1000px;
  font-family: 'Montserrat', sans-serif;

  .section-title {
    text-align: center;
    margin-bottom: 1rem;
    h1 {
      color: ${COLORS.blueLight};
      font-size: 2rem;
      text-transform: uppercase;
      margin: 0;
    }
    h2 {
      color: ${COLORS.primary};
      margin-top: 0;
    }
  }

  .speaker-tile {
    margin-bottom: 1rem;
    display: grid;
    grid-template-columns: min-content 1fr;
    color: #100f21;
    border-radius: 6rem;
    &:hover {
      cursor: pointer;
    }

    &.direction-r {
      grid-template-columns: 1fr min-content;

      .speaker-info {
        text-align: right;
        order: -1;
      }
    }

    .gatsby-image-wrapper {
      align-self: center;
      max-width: 180px;
      max-height: 180px;
      width: 180px;

      img {
        border-radius: 50%;
        -o-object-fit: cover;
        object-fit: cover;
        -o-object-position: top;
        object-position: top;
        border-radius: 50%;
        border: 5px solid ${COLORS.yellow};
      }
    }
    .speaker-info {
      padding: 1rem;
      align-self: center;

      .title {
        font-weight: 700;
        font-size: 1.4rem;
      }
      .name {
        font-weight: 600;
        font-size: 1.2rem;
      }
      .company {
        font-size: 1.2rem;
        color: #6ecce0;
      }
    }
    ${breakpointsMediaQueries.md} {
      .gatsby-image-wrapper {
        max-width: 120px;
        max-height: 120px;
        width: 120px;
      }
      .speaker-info {
        .title {
          font-weight: 600;
          font-size: 1rem;
        }
        .name {
          font-size: 0.8rem;
        }
        .company {
          font-size: 0.8rem;
        }
      }
    }
  }
`;
const WorkshopTopicSection = ({speakers}: {speakers: any}) => {
  const breakpoints = useBreakpoint();

  return (
    <ContainerLg>
      <StyledAgenda>
        <div className="section-title">
          <h1>Workshop</h1>
          <h2 style={{color: COLORS.headlineSubtitleColor}}>
            Tickets sold separately - Limited number of tickets available!
          </h2>
        </div>
        {speakers.map((speaker: any, index: number) => {
          const image =
            speaker.node.frontmatter.imageSrc &&
            getImage(
              breakpoints.sm
                ? speaker.node.frontmatter.imageSrc.childImageSharp.small
                : speaker.node.frontmatter.imageSrc.childImageSharp.large
            );
          return (
            <Link key={speaker.node.id} to={`/speakers/${speaker.node.slug}`}>
              <div
                className={`speaker-tile direction-${index % 2 ? 'r' : 'l'}`}
                key={speaker.node.id}
              >
                {image && <GatsbyImage image={image} alt={speaker.node.frontmatter.fullName} />}
                <div className="speaker-info">
                  <div className="title">{speaker.node.frontmatter.talkTitle}</div>
                  <div className="name">{speaker.node.frontmatter.fullName}</div>
                  <div className="company">{speaker.node.frontmatter.company}</div>
                  <StyledButton>Read more</StyledButton>
                </div>
              </div>
            </Link>
          );
        })}
      </StyledAgenda>
    </ContainerLg>
  );
};
export default WorkshopTopicSection;
