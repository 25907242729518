import React from 'react';
import styled from 'styled-components';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faLocationDot, faGauge, faTicket} from '@fortawesome/free-solid-svg-icons';

import {ContainerLg} from '../components';
import {breakpointsMediaQueries} from '../../style/my-theme';

const SECTION_INFO = {
  title: 'VENUE INFO',
  location: 'CineStar Novi Sad 4DX (BIG Shopping center)',
  distance: 'Distance to city center: 4 km',
  parking: 'Free parking'
};

const StyledVenueSection = styled.section.attrs({
  className: 'venue-section'
})`
  display: grid;
  grid-template-columns: auto auto;
  color: #fff;
  background-image: url('/images/footer-stickers.jpeg');

  ${breakpointsMediaQueries.md} {
    grid-template-columns: auto;
  }

  .container-lg {
    ${breakpointsMediaQueries.md} {
      margin: 20px 40px;
    }
    .venue-info {
      display: flex;
      flex-direction: column;
      span {
        display: flex;
        gap: 1rem;
        align-items: center;
        text-align: left;
        svg {
          width: 1rem;
          color: #f6df19;
        }
      }
    }
  }
  .mapouter {
    position: relative;
    margin-left: auto;
    text-align: right;
    height: 400px;
    width: 100%;
    .gmap_canvas {
      overflow: hidden;
      background: none !important;
      height: 400px;
    }
  }
`;

const VenueSection = () => (
  <StyledVenueSection>
    <ContainerLg>
      <h2>{SECTION_INFO.title}</h2>
      <div className="venue-info">
        <span>
          <FontAwesomeIcon icon={faLocationDot} />
          {SECTION_INFO.location}
        </span>
        <span>
          <FontAwesomeIcon icon={faGauge} />
          {SECTION_INFO.distance}
        </span>
        <span>
          <FontAwesomeIcon icon={faTicket} />
          {SECTION_INFO.parking}
        </span>
      </div>
    </ContainerLg>
    <div className="mapouter">
      <div className="gmap_canvas">
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2807.6634750579265!2d19.826403915468404!3d45.27481305408167!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x475b10fe0624963b%3A0x207f3d36d1de004c!2sCineStar%20Novi%20Sad%204DX%20(BIG%20Shopping%20center)!5e0!3m2!1sen!2srs!4v1656091761726!5m2!1sen!2srs"
          width="100%"
          height="450"
          style={{border: 0}}
          loading="lazy"
          referrerPolicy="no-referrer-when-downgrade"
        ></iframe>
      </div>
    </div>
  </StyledVenueSection>
);

export default VenueSection;
